var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "branchList" },
    [
      _c(
        "ul",
        _vm._l(_vm.paginatedBranches, function (branch) {
          return _c(
            "li",
            _vm._b(
              { class: { active: branch.active } },
              "li",
              _vm.iconHoverContainerProps(branch.id),
              false
            ),
            [
              _c(
                "link-wrap",
                {
                  attrs: {
                    link: _vm.isSelectMode ? "#select-branch" : branch.url,
                    type: _vm.isSelectMode ? "js" : "auto",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onBranchClick(branch)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "detail" },
                    [
                      _c(
                        "icon",
                        _vm._b(
                          { attrs: { icon: "arrowLeft" } },
                          "icon",
                          _vm.iconHoverProps(branch.id),
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.$t("branchSearch.list.name.prefix")) +
                        " " +
                        _vm._s(branch.name)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    branch.address
                      ? _c("span", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(branch.address) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    branch.zip || branch.city
                      ? _c("span", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(branch.zip) +
                              " " +
                              _vm._s(branch.city) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    [
                      branch.phone
                        ? _c(
                            "LinkWrap",
                            { attrs: { link: branch.phone } },
                            [
                              _c("Icon", { attrs: { icon: "phone" } }),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm._f("phoneNumber")(branch.phone)) +
                                  "\n                    "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      branch.email
                        ? _c(
                            "MailWrap",
                            { attrs: { address: branch.email } },
                            [
                              _c("icon", { attrs: { icon: "mail" } }),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(branch.email) +
                                  "\n                    "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isSelectMode
                        ? _c(
                            "span",
                            { staticClass: "branchList__selectLink" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("branch.select")) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("infinite-loading", {
        attrs: { identifier: _vm.infiniteId },
        on: { infinite: _vm.onInfinite },
        scopedSlots: _vm._u([
          {
            key: "no-more",
            fn: function () {
              return [_vm._v(" ")]
            },
            proxy: true,
          },
          {
            key: "no-results",
            fn: function () {
              return [
                _c(
                  "EmptyListNotification",
                  { attrs: { "has-green-background": true } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("branchSearch.form.search.noResults")) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }