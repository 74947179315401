






































































import EmptyListNotification from '../../../../../../Component/Misc/EmptyListNotification/EmptyListNotification.vue';
import Icon from '../../../../../../Component/Misc/Icon/Icon.vue';
import IconHoverMixin from '../../../../../../Component/Misc/Icon/IconHoverMixin';
import LinkWrap from '../../../../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';
import MailWrap from '../../../../../../Component/Misc/Link/MailWrap/MailWrap.vue';
import {Branch, MapBranch} from '../../../../../../Interface/Branch';

const branchesPerPage = 15;

export default {
    name: 'BranchSearch_BranchList',
    components: {Icon, MailWrap, LinkWrap, EmptyListNotification},
    mixins: [IconHoverMixin],
    props: {
        isSelectMode: {
            type: Boolean,
            default: false
        },
        branches: {
            type: Array,
            required: true
        }
    },
    data()
    {
        return {
            infiniteId: 0,
            page: 0
        };
    },
    computed: {
        pages(): number
        {
            return Math.ceil(this.branches.length / branchesPerPage);
        },

        paginatedBranches(): Array<Branch>
        {
            return this.branches.slice(0, this.page * branchesPerPage);
        }
    },
    methods: {

        onInfinite(state)
        {
            const newPage = Math.min(this.pages, this.page + 1);
            if (newPage === this.page) {
                state.complete();
            }
            this.page = newPage;
            state.loaded();
        },

        onBranchClick(branch: MapBranch)
        {
            if (this.isSelectMode) {
                this.$emit('branch:selected', branch);
            }
        }
    },
    watch: {
        branches()
        {
            this.page = 0;
            this.infiniteId++;
        }
    }
};
